.intro {
  display: flex;
  height: 77vh;
  margin-top: 6rem;
}
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
}
.i-name > :nth-child(1) {
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
  z-index: -1;
}
.i-name > :nth-child(2) {
  color: var(--orange);
  font-size: 3rem;
  font-weight: bold;
}
.i-name > :nth-child(3) {
  font-weight: 200;
  font-size: 14px;
  color: var(--gray);
  margin: 5px 0px;
}
.i-button {
  width: 6rem;
  height: 2rem;
}

.i-icons {
  margin-top: 3rem;
  display: flex;
  gap: 0;
}
.i-icons > * {
  transform: scale(0.4);
}
.i-icons > *:hover {
  cursor: pointer;
}

.i-right {
  flex: 1;
  position: relative;
}
.i-right > * {
  position: absolute;
  z-index: 1;
}
.i-right > :nth-child(1) {
  transform: scale(0.69);
  left: -15%;
  top: -9%;
}
.i-right > :nth-child(2) {
  transform: scale(0.67);
  left: -3rem;
  top: -11.6%;
}
.i-right > :nth-child(3) {
  transform: scale(1.5);
  bottom: 40%;
  left: 17%;
}
.i-right > :nth-child(4) {
  transform: scale(0.6);
  left: -24%;
  border-radius: 50%;
  top: -19%;
}
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(72px);
  z-index: -9;
  top: -18%;
  left: 56%;
}

@media screen and (max-width: 680px) {
  .intro {
    flex-direction: column;
    height: 64rem;
    gap: 7rem;
  }
  .i-right {
    transform: scale(0.7);
    left: -3rem;
  }
  /* .i-left{
        z-index: -1;
    }
    */
  .i-right .blur {
    display: none;
  }

  .flotingdiv:nth-last-of-type(1) {
    top: -7rem !important;
  }
  .i-right > :nth-child(3) {
    transform: scale(1.2);
    bottom: 9%;
    left: 18%;
  }
}
