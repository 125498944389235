:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --blueCard: #ddf8fe;
  --purple: rgb(238 210 255);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 12%);
  --orangeCard: rgba(248, 172, 48, 0.856);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App {
  padding: 0.5rem 3.5rem;
  overflow: hidden;
  color: var(--black);
}
.button {
  border-radius: 34px;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  padding: 10px 26px;
  background: linear-gradient(180deg, #fdc50f 26.72%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(252, 162, 40, 0.42);
}
.button:hover {
  background: white;
  cursor: pointer;
  color: var(--orange);
  border: 1px solid var(--orange);
}
@media screen and (max-width: 680px) {
  .App {
    padding: 0.5rem 1rem;
  }
}
